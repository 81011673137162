import classNames from "classnames";
import useGlobalHeaderContent from "../../lib/ButterCMSContent/useGlobalHeaderContent";
import DownloadButton from "../Home_Redesign/DownloadButton/DownloadButton";
import styles from "./NavAppDownload.module.scss";

const defaultNavContent = {
  cta_text: "Download the app",
  cta_mobile_subtext: "Subscribe to an EV. Get started today.",
};

export default function NavAppDownload() {
  const globalHeaderContent = useGlobalHeaderContent();
  const {cta_mobile_subtext, cta_text, cta_mobile_text} = {
    ...defaultNavContent,
    ...globalHeaderContent,
  };

  return (
    <>
      <div className="d-none d-lg-block mx-xl-4">
        <DownloadButton
          className={styles.downloadBtn}
          showWebReservationOnMobile
        >
          {cta_text}
        </DownloadButton>
      </div>
      <div className={classNames("d-block d-lg-none", styles.mobileCTA)}>
        <div className={styles.divider} />
        <p className={styles.subtext}>{cta_mobile_subtext}</p>
        <DownloadButton className={styles.downloadBtn}>
          {cta_mobile_text}
        </DownloadButton>
      </div>
    </>
  );
}
