import React, {useState} from "react";
import Image from "next/image";
import Navbar from "react-bootstrap/Navbar";
import bars from "../../public/images/nav/bars.svg";
import bars_white from "../../public/images/nav/bars_white.svg";
import closeWhite from "../../public/images/nav/close_white.svg";
import closeBlack from "../../public/images/nav/close.svg";
import {TYPE_THEME} from "../../lib/constants";

import styles from "./NavbarReimagined.module.scss";
import NavbarNav from "./NavbarNav";
import NavbarBrand from "./NavbarBrand";
import {Nav} from "react-bootstrap";
import NavAppDownload from "./NavAppDownload";
import classNames from "classnames";

export default function NavbarReimagined({
  isFixed,
  isSticky,
  navBarRef,
  onMobileMenuOpen,
  theme = TYPE_THEME.LIGHT,
}) {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMobileMenu = () => {
    const newOpenMenu = !openMenu;
    onMobileMenuOpen?.(newOpenMenu);
    setOpenMenu(newOpenMenu);
  };

  const forcedTheme = openMenu ? TYPE_THEME.LIGHT : theme;
  const isDarkTheme =
    forcedTheme === TYPE_THEME.DARK || forcedTheme === TYPE_THEME.TRANSPARENT;

  return (
    <>
      <Navbar
        data-autonomy-reservation-form
        className={classNames(styles.navBar, styles[`theme_${forcedTheme}`], {
          [styles.hasOpenedMenu]: openMenu,
        })}
        expand="lg"
        fixed={isFixed ? "top" : undefined}
        ref={navBarRef}
        sticky={isSticky ? "top" : undefined}
      >
        <div className={classNames("container", styles.nowrap)}>
          <NavbarBrand theme={forcedTheme} />
          <Navbar.Toggle
            className={styles.navBarToggle}
            aria-controls="basic-navbar-nav"
            onClick={toggleMobileMenu}
          >
            {openMenu ? (
              <Image
                alt="Close"
                height={15}
                priority
                src={isDarkTheme ? closeWhite : closeBlack}
                width={15}
              />
            ) : (
              <Image
                alt="Three Horizontal Bars"
                height={12}
                src={isDarkTheme ? bars_white : bars}
                width={21}
              />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse
            className={styles.navBarCollapse}
            id="basic-navbar-nav"
            data-autonomy-reservation-form
          >
            <NavbarNav />
            <Nav className={styles.navAppDownload}>
              <NavAppDownload />
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
