import classNames from "classnames";
import {useContext, useState} from "react";
import {Button} from "react-bootstrap";
import {buttonClickedEvent} from "../../../lib/analytics";
import useAppDownload from "../../../lib/ButterCMSContent/useAppDownload";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import useOnlyOnMount from "../../hooks/useOnlyOnMount";
import ActivationFlowModal from "../../Shared/ActivationFlowModal/ActivationFlowModal";
import WebReservationModal from "../../Shared/WebReservationModal/WebReservationModal";
import styles from "./DownloadButton.module.scss";
import PaymentReservationContext from "../../context/paymentReservation";
import {useFlags} from "launchdarkly-react-client-sdk";
import VehicleReservationOnlyModal from "../../VehicleReservationOnly/VehicleReservationOnlyModal";

export default function DownloadButton({
  className,
  children = "Get Started",
  showWebReservationOnMobile,
  showWebReservation,
  size,
  ...rest
}) {
  const {showAvailableVehicles} = useFlags();

  const {selectedPricingOption} = useContext(PaymentReservationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {appDownloadLink} = useAppDownload();

  const onlyOnMount = useOnlyOnMount();
  const isMobileDevice = useIsMobileDevice();

  const hideModal = () => setIsModalOpen(false);
  const showModal = () => {
    buttonClickedEvent(children, "", "");

    if (showWebReservationOnMobile || !isMobileDevice) {
      setIsModalOpen(true);
    }
  };

  const selectedStartFee = selectedPricingOption
    ? selectedPricingOption.startFee / 100
    : undefined;
  const selectedMonthlyRent = selectedPricingOption
    ? selectedPricingOption.monthlyRent / 100
    : undefined;
  const selectedPriceTermMonths = selectedPricingOption?.priceTermMonths;

  const href =
    onlyOnMount && isMobileDevice && !showWebReservationOnMobile
      ? appDownloadLink.url
      : undefined;

  return (
    <>
      <Button
        {...rest}
        href={href}
        className={classNames(styles.downloadBtn, className, {
          [styles.largeButton]: size === "large",
        })}
        onClick={showModal}
        variant="secondary"
      >
        {children}
      </Button>
      {showWebReservation ? (
        showAvailableVehicles ? (
          <VehicleReservationOnlyModal onHide={hideModal} show={isModalOpen} />
        ) : (
          <WebReservationModal
            onHide={hideModal}
            show={isModalOpen}
            startFee={selectedStartFee}
            monthlyPayment={selectedMonthlyRent}
            priceTermMonths={selectedPriceTermMonths}
          />
        )
      ) : (
        <ActivationFlowModal onHide={hideModal} show={isModalOpen} />
      )}
    </>
  );
}
