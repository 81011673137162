import {useRouter} from "next/router";
import {useMemo} from "react";
import {useContext} from "react";
import GlobalButterCMSContentContext from "../../components/context/globalButterCMSContent";

function sanitizeUrl(url_slug) {
  if (url_slug.startsWith("http") || url_slug.startsWith("/")) {
    return url_slug;
  }

  return `/${url_slug}`;
}

export default function useGlobalHeaderContent() {
  const {pathname} = useRouter();
  const {global_header = {}} = useContext(GlobalButterCMSContentContext);

  const navItems = useMemo(() => {
    const rawContent = global_header?.nav_items;

    function createNavItem({
      is_mobile_only,
      mobile_menu_item,
      menu_item,
      url_slug,
    }) {
      const url = sanitizeUrl(url_slug);

      return {
        is_mobile_only,
        isActive: pathname === url,
        menu_item,
        mobile_menu_item,
        url,
      };
    }

    return rawContent.map(({reference, ...navItemContent}) => {
      if (reference.length > 0) {
        return {
          children: reference.map(createNavItem),
          hasChildren: true,
          is_mobile_only: navItemContent.is_mobile_only,
          menu_item: navItemContent.menu_item,
          mobile_menu_item: navItemContent.mobile_menu_item,
        };
      }

      return createNavItem(navItemContent);
    });
  }, [global_header, pathname]);

  return {
    ...global_header,
    navItems,
  };
}
