import {React} from "react";
import {Modal} from "react-bootstrap";
import ReservationSubmitionForm from "../../Home/ReservationSubmitionForm/ReservationSubmitionForm";

export default function WebReservationModal(props) {
  const {
    onHide: closeModal,
    startFee,
    monthlyPayment,
    priceTermMonths,
    ...rest
  } = props;

  const onHide = () => {
    closeModal();
  };

  return (
    <Modal {...rest} onHide={onHide}>
      <Modal.Header closeButton style={{borderBottom: 0}}></Modal.Header>
      <Modal.Body style={{padding: 10, paddingBottom: 30}}>
        <ReservationSubmitionForm
          startFee={startFee}
          monthlyPayment={monthlyPayment}
          priceTermMonths={priceTermMonths}
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
}
