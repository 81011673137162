import {useEffect, useState} from "react";

export function checkIfMobileDevice() {
  const userAgent = window.navigator.userAgent;
  return [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    // /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ].some((possibleMatch) => userAgent.match(possibleMatch));
}

// NOTE: only intended to be used client side with user interactions,
// otherwise will cause issues with server side rendering
export default function useIsMobileDevice() {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(checkIfMobileDevice());
  }, []);

  return isMobileDevice;
}

export function useIsIOSDevice() {
  const [isIosDevice, setIsIosDevice] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    setIsIosDevice(
      [/iPhone/i, /iPad/i].some((possibleMatch) =>
        userAgent.match(possibleMatch)
      )
    );
  }, []);

  return isIosDevice;
}
